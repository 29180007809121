import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyProfileDocumentsDialogComponent } from 'src/app/components';
import {
  CompanyItemStatus,
  CompanyItemTypes,
  CompanyTypeEnum,
  CompanyVerificationStatus,
  FOUND_PAGE,
} from 'src/app/enums';
import { AuthService, CompanyService, FileService, ModalService, ProgressIndicatorService } from 'src/app/services';
import { CompanyAlias, User } from 'src/app/types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
})
export class CompanyProfileComponent implements OnInit {

  host: string = environment.serviceHost;

  loading = true;
  currentUser: User;
  id: number;
  company;
  CompanyType = CompanyTypeEnum;
  CompanyVerificationStatus = CompanyVerificationStatus;
  fields = [
    'id',
    'type_id',
    'type',
    'name',
    'address',
    'phone',
    'website',
    'primary_contact',
    'users{title,is_ar,is_enabled},files,departments{department_affiliations{user{title,is_enabled},is_ar}}',
    'signator{title}',
    'primary_contact{title}',
    'trade_ids',
    'file_items',
    'is_enabled',
    'contact_name',
    'contact_email',
    'verification_status',
  ];
  detailsFormGroup: FormGroup = this.fb.group({});
  companyItemTypes = CompanyItemTypes;
  companyItemTypeValues: (string | {})[] = Object.values(CompanyItemTypes).filter((value) => typeof value === 'number');
  companyItemStatuses = CompanyItemStatus;
  companyItemStatusValues = Object.values(CompanyItemStatus).filter((value) => typeof value === 'number');
  canEdit = false;

  constructor(
    public authService: AuthService,
    private activeRoute: ActivatedRoute,
    private companyService: CompanyService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private fb: FormBuilder,
    private progressIndicatorService: ProgressIndicatorService,
    private modalService: ModalService,
    private fileService: FileService
  ) {}

  async ngOnInit(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading...');
    this.currentUser = this.authService.getLoggedInUser();
    this.canEdit =
      this.authService.isAppAdmin ||
      this.authService.isACOnAnyModule ||
      this.authService.isDirectoryManager ||
      this.authService.isCompanyEditor(this.id);
    this.id = +this.activeRoute.snapshot.paramMap.get('id');
    const company = await this.companyService.getCompanyById(this.id, this.fields).toPromise();
    if (company) {
      company.departments = company.departments.map((d) => {
        d.users = d.department_affiliations.map((da) => {
          return { ...da.user, is_ar: da.is_ar };
        });
        return d;
      });
      company.trade_ids = company.trade_ids ? JSON.parse(company.trade_ids) : [];
      this.companyItemTypeValues = this.companyItemTypeValues.map((tid) => {
        const fileItem = company.file_items?.find((fi) => fi.type_id === tid);
        return { tid, fileItem };
      });
      company.name_aliases = await this.companyService.findAllCompanyAliasByCompanyId(company.id).toPromise();
      this.company = company;

      this.detailsFormGroup = this.fb.group({
        name: [this.company.name, [Validators.required]],
        address: [this.company.address],
        phone: [this.company.phone],
        website: [this.company.website],
        is_enabled: [this.company.is_enabled === 1 ? true : false],
      });
    } else {
      this._router.navigate(['/404'], {
        queryParams: {
          status: FOUND_PAGE.NOT_FOUND,
        },
      });
    }
    this.progressIndicatorService.close();
    this.loading = false;
  }

  public EditItem(ci) {
    this._dialog
      .open(CompanyProfileDocumentsDialogComponent, {
        width: '580px',
        data: {
          action: 'Add ' + this.companyItemTypes[ci.tid],
          company_id: this.company.id,
          type_id: ci.tid,
          companyItem: ci.fileItem,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          ci.fileItem = result;
        }
      });
  }

  deleteItem(ci) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete Item',
        descriptionText: 'Are you sure you want to delete this item?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Deleting...');
          if (ci?.fileItem?.file?.file_id) {
            await this.fileService.deleteFileWithoutConfirmation({ id: ci?.fileItem?.file?.file_id }).toPromise();
          }
          await this.companyService.deleteCompanyItem(ci.fileItem.id).toPromise();
          ci.fileItem = null;
          this.progressIndicatorService.close();
        }
      });
  }

  editCompany() {
    this.modalService
      .openCompanyDialog({
        company: this.company,
      })
      .toPromise()
      .then((result) => {
        if (result) {
          result.trade_ids = result.trade_ids ? JSON.parse(result.trade_ids) : [];
          this.company = { ...this.company, ...result };
        }
      });
  }

  showSupplierVerification() {
    return (
      (this.authService.isAppAdmin || this.authService.isAC) &&
      (this.company?.verification_status == 2 || this.company?.verification_status == 3)
    );
  }

  getFormattedAddress() {
    return this.company?.address
      ? `${this.company.address.address}<br>${this.company.address.city}, ${this.company.address.state}<br>${this.company.address.zipcode}`
      : '';
  }

  async updateProfile() {
    if (!this.detailsFormGroup.valid) {
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving...');
    const companyToUpdate = this.detailsFormGroup.value;
    companyToUpdate.phone = companyToUpdate.phone || null;
    companyToUpdate.address = companyToUpdate.address || null;
    companyToUpdate.website = companyToUpdate.website || null;
    companyToUpdate.is_enabled = companyToUpdate.is_enabled === true ? 1 : 0;
    const company = (await this.companyService
      .updateCompany(this.company.id, companyToUpdate, this.fields)
      .toPromise()) as any;
    company.departments = company.departments.map((d) => {
      d.users = d.department_affiliations.map((da) => {
        return { ...da.user, is_ar: da.is_ar };
      });
      return d;
    });
    this.company = company;
    this.progressIndicatorService.close();
  }

  cancel() {
    this.detailsFormGroup.patchValue({
      name: this.company.name,
      address: this.company.address,
      phone: this.company.phone,
      website: this.company.website,
      is_enabled: this.company.is_enabled === 1 ? true : false,
    });
  }

  getCompaniesAliasNames(){
    return this.companyService.getCompaniesAliasNames(this.company?.name_aliases);
  }
}
