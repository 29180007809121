<app-base-dialog title="{{ data?.company?.id ? 'Edit' : 'Add' }} Company" (closeDialog)="cancel()"></app-base-dialog>
<div class="company-dialog" style="min-width: 380px">
  <div class="d-flex flex-column br-10 bg-shade-red p-3 mb-4" *ngIf="!data?.company?.id">
    <h6 class="primary mb-2">For Supplier Companies</h6>
    <p class="dkgray mb-0">
      Each supplier company must be registered with the state and verified by the Inventory Control Officer (ICO).
      Creating a new company will automatically submit a request for it to be reviewed and verified. Bids CANNOT be
      awarded to a supplier if it has not yet been verified. You will be notified as soon as it is updated.
    </p>
  </div>
  <form [formGroup]="companyFormGroup">
    <div mat-dialog-content class="companyDialogForm">
      <div
        *ngIf="fuzzyMatchingCompanies?.length && !showSuggestions"
        class="br-10 bg-shade-ltblue dkblue p-3 d-flex align-items-center mb-4"
      >
        <mat-icon class="mr-2 ltblue">error</mat-icon>
        <h6 class="mb-0 mr-auto">There are {{ fuzzyMatchingCompanies?.length }} companie(s) with a similar name</h6>
        <button (click)="showSuggestions = !showSuggestions" mat-button type="button" color="accent" class="lh-24">
          View
        </button>
      </div>
      <div class="mb-5" *ngIf="showSuggestions">
        <div class="d-flex align-items-start">
          <div class="d-flex flex-column mr-auto">
            <h6 class="primary mb-0">Suggested Companies</h6>
            <p class="dkgray">Already created companies with similar names</p>
          </div>
          <p class="dkgray mb-0">
            {{
              displayExistingCompanyMax < fuzzyMatchingCompanies?.length
                ? displayExistingCompanyMax
                : fuzzyMatchingCompanies?.length
            }}
            of {{ fuzzyMatchingCompanies?.length }}
          </p>
        </div>
        <div id="companies" style="max-height: 192px; overflow-y: scroll">
          <div *ngFor="let company of fuzzyMatchingCompanies; let i = index">
            <div class="d-flex mb-2 align-items-center border p-3 br-5" *ngIf="i < displayExistingCompanyMax">
              <h5 class="primary mb-0 mr-auto">{{ company.name }}</h5>
              <button
                class="lh-30"
                mat-flat-button
                color="accent"
                type="button"
                (click)="selectExistingCompany(company)"
                [disabled]="data.disableCompanySelection"
              >
                Use this Company
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between py-2">
          <button
            mat-button
            color="accent"
            *ngIf="fuzzyMatchingCompanies.length > displayExistingCompanyMax"
            (click)="showMoreCompanies()"
          >
            Show More Suggestions
          </button>
          <button mat-button class="gray" *ngIf="showSuggestions" (click)="showSuggestions = false">
            Hide Suggestions
          </button>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <h6 class="primary mb-2">Company Name</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input
              matInput
              type="text"
              required="true"
              placeholder="Enter Company Name"
              formControlName="name"
              (ngModelChange)="searchExistingCompanies()"
            />
            <mat-error *ngIf="name.errors?.required">Company Name is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <h6 class="primary mb-2">Company Type</h6>
          <mat-form-field class="full-width" appearance="outline">
            <mat-select required="true" formControlName="type_id" readonly>
              <mat-option *ngFor="let ct of companyTypes" [value]="ct.id">{{ ct.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="type_id.errors?.required">Company Type is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-2" *ngIf="name_aliases_controls.length > 0">
        <div class="col">
          <h6 class="primary">Company Alias</h6>
        </div>
      </div>

      <div class="row mb-3" *ngFor="let fbControl of name_aliases_controls; let i = index">
        <div class="col-11">
          <mat-form-field class="full-width" appearance="outline">
            <input matInput type="text" required="true" placeholder="Company Alias" [formControl]="fbControl" />
            <mat-error *ngIf="fbControl.errors?.required">Company alias is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-1 pt-1 ml-0 pl-0">
          <mat-icon color="secondary" class="mt-2 delete-alias gray" title="Remove" (click)="removeCompanyAlias(i)"
            >delete</mat-icon
          >
        </div>
      </div>

      <div class="row mb-3" [ngClass]="{ 'mt-2': name_aliases_controls.length > 0 }">
        <div class="col">
          <button (click)="addCompanyAlias()" mat-button type="button" color="accent" class="flex; p">
            <span class="accent">+ Add Company Alias</span>
          </button>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-6">
          <h6 class="primary mb-2">Contact Name</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input
              matInput
              type="text"
              required="true"
              placeholder="Enter Contact Name"
              formControlName="contact_name"
            />
            <mat-error *ngIf="contact_name.errors?.required">Contact Name is required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-6">
          <h6 class="primary mb-2">Contact Email</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput type="text" placeholder="name@example.com" formControlName="contact_email" />
            <mat-error *ngIf="contact_email.errors?.required">Contact Email is required</mat-error>
            <mat-error *ngIf="contact_email.errors?.email">Contact Email is not valid</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-6">
          <h6 class="primary mb-3">Company Phone</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput type="text" formControlName="phone" placeholder="Company Phone" />
          </mat-form-field>
        </div>
        <div class="col-6">
          <h6 class="primary mb-3">Company Website</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput type="text" formControlName="website" placeholder="Company website" />
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="row mb-4">
        <div class="col-6">
          <h6 class="primary mb-3">Primary Contact</h6>
          <app-search-user-input #primary_contact [userTypes]="userTypes" placeholder="Primary Contact">
          </app-search-user-input>
        </div>
        <div class="col-6">
          <h6 class="primary mb-3">Signator</h6>
          <app-search-user-input #signator [userTypes]="userTypes" placeholder="Signator"> </app-search-user-input>
        </div>
      </div> -->
      <div class="p-3 border border-gray br-10 mb-4">
        <div class="d-flex align-items-center">
          <h5 id="physicalAddressLabel" class="primary mb-0 mr-auto">Address</h5>
          <mat-slide-toggle
            aria-labelledby="physicalAddressLabel"
            class="ml-auto"
            color="accent"
            [formControl]="isAddressActive"
          ></mat-slide-toggle>
        </div>
        <div formGroupName="address" *ngIf="isAddressActive.value">
          <div class="row my-3">
            <div class="col-12">
              <h6 class="primary mb-2">Address Line 1</h6>
              <mat-form-field class="full-width" appearance="outline">
                <input matInput type="text" placeholder="Address Line 1" formControlName="address" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mb-2">
              <h6 class="primary mb-2">City</h6>
              <mat-form-field class="full-width" appearance="outline">
                <input matInput type="text" placeholder="e.g. Oklahoma City" formControlName="city" />
              </mat-form-field>
            </div>
            <div class="col-3 mb-2 pl-0">
              <h6 class="primary mb-2">State</h6>
              <mat-form-field class="full-width state-select" appearance="outline">
                <mat-select placeholder="State" formControlName="state">
                  <mat-select-trigger>{{ address.value.state }}</mat-select-trigger>
                  <mat-option *ngFor="let state of USstates" [value]="state.abbreviation">{{ state.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-3 mb-2 pl-0">
              <h6 class="primary mb-2">Zip Code</h6>
              <mat-form-field class="full-width" appearance="outline">
                <input matInput type="text" placeholder="Zip Code" formControlName="zipcode" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="data?.company?.id" class="p-3 border border-gray br-10 mb-4 d-flex align-items-center">
        <div class="d-flex flex-column mr-auto">
          <h6 class="primary mb-0 lh-18">Company is Verified</h6>
          <p class="dkgray mb-0 lh-18">Company has been confirmed to be in good-standing with the State of Oklahoma</p>
        </div>
        <mat-slide-toggle
          aria-labelledby="physicalAddressLabel"
          class="ml-5"
          color="accent"
          formControlName="verified"
        ></mat-slide-toggle>
      </div> -->
      <div
        *ngIf="canEditCompany && data?.company?.id"
        class="p-3 border border-gray br-10 mb-4 d-flex align-items-center"
      >
        <div class="d-flex flex-column mr-auto">
          <h6 class="primary mb-0 lh-18">Company is Active</h6>
          <p class="dkgray mb-0 lh-18">Company is able to submit bids</p>
        </div>
        <mat-slide-toggle
          aria-labelledby="physicalAddressLabel"
          class="ml-auto"
          color="accent"
          formControlName="enabled"
        ></mat-slide-toggle>
      </div>
      <div *ngIf="showSupplierVerification" class="p-3 border border-gray br-10 mb-4 d-flex align-items-center">
        <div class="d-flex flex-column mr-auto">
          <h6 class="primary mb-0 lh-18">Supplier Verification</h6>
          <p class="dkgray mb-0 lh-18">Supplier has been verified for use with:</p>
        </div>
        <mat-form-field appearance="outline">
          <mat-select formControlName="verification_status">
            <mat-option [value]="1">Pending</mat-option>
            <mat-option [value]="2">UHAT Only</mat-option>
            <mat-option [value]="3">1CALL & UHAT</mat-option>
            <mat-option [value]="4">Suspended</mat-option>
          </mat-select>
          <mat-error *ngIf="type_id.errors?.required">Company Type is required</mat-error>
        </mat-form-field>
      </div>

      <div class="row mb-4" *ngIf="data?.showTrades">
        <div class="col-12">
          <h6 class="primary mb-3">Company Trades</h6>
          <mat-form-field class="full-width" appearance="outline">
            <mat-select matNativeControl required="true" formControlName="trade_ids" multiple>
              <mat-option *ngFor="let t of trades" [value]="t.id">{{ t.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="trade_ids.errors?.required">Trades are required</mat-error>
          </mat-form-field>
          <!-- <mat-selection-list matNativeControl required="true" formControlName="trade_ids" #trades>
              <mat-list-option *ngFor="let t of trades" [value]="t.id">{{t.name}}</mat-list-option>
            </mat-selection-list> -->
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <button mat-button type="button" class="full-width gray" color="accent" (click)="cancel()">Cancel</button>
      </div>
      <div class="col">
        <button mat-flat-button type="submit" color="accent" class="full-width" (click)="submit()">
          Submit Company
        </button>
      </div>
    </div>
  </form>
</div>
